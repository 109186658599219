.btn_icon_container {
    color: #000;
    background: #fff;
    border-radius: 50px;
    height: 27px;
    width: 27px;
    text-align: center;
    line-height: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}