.section {
    padding: 1rem 0;
}

.section .section_title h6 {
    text-decoration: dotted underline var(--main-color) 5px;
    padding-bottom: 5px;
}

.service_card {
    box-shadow: 0px 0px 48px 0px rgba(4, 6, 4, 0.08);
}

li::marker {
    color: var(--main-color);
    font-size: 1rem;
    font-weight: bold;
}

/*
.skills-list {
    list-style: none;
    width: 350px;
    background: #ecf5ff;
    padding: 30px;
    border-radius: 4px;
}

.skills-list li {
    padding: 10px 0 10px 0;
}

.skills-list li:nth-child(odd) {
    text-align: left;
    border-left: 5px dotted #1488F3;
    padding-left: 4px;
    border-radius: 10px;
    border-bottom: 5px dotted #86bfff;
}

.skills-list li:nth-child(even) {
    text-align: right;
    border-radius: 10px;
    border-right: 5px dotted #1488F3;
    padding-right: 4px;
    border-bottom: 5px dotted #86bfff;
}*/