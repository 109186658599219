.navBar {
	background: #fff;
	display: inline-block;
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	height: 70px;
	width: 100%;
	margin-bottom: 30px;
	line-height: 70px;
	box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.07);
	-webkit-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.07);
	-moz-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.07);
}

.navBar .home-btn {
	background-color: #ffc500;
	color: #fff;
	border-radius: 6px 0 0 6px;
	-webkit-border-radius: 6px 0 0 6px;
	-moz-border-radius: 6px 0 0 6px;
	padding: 0px 24px;
	float: left;
	height: 70px;
	line-height: 70px;
	margin-right: 10px;
	transition: all ease 0.4s;
	line-height: 77px;
}
.navBar .home-btn:hover {
	background-color: #ffb100;
	box-shadow: 2px 2px 2px aqua;
}
.header_button svg {
	transform: rotate(-61deg);
	font-size: 16px;
}

.navBar .MuiToolbar-root {
	padding-left: 0;
}

.navBar a {
	padding: 6px 8px;
	text-decoration: none;
	font-size: 14px;
	font-weight: bold;
	color: #000;
	padding: 0 10px;
	line-height: 73px;
	display: block;
	text-transform: uppercase;
}

.active-link {
	color: #edff00;
}

.navBar a.active {
	color: var(--main-color);
}
.nav-links a:hover,
.nav-links a.active {
	text-decoration: dotted underline var(--main-color) 5px;
}

