.App {
  text-align: center;
}
:root {
  --main-color: #ffc500;
  --secondary-color:#ffb100;
}
body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  position: relative;
  background: url(../src/assets/images/bg.png);
}
.container_shadow {
  box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
}
.timeline_dot_header+span {
  width: 0px;
}

.container_shadow {
  box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
}
.main_content {
  background-color: #fff;
  min-height: 100vh;
  border-radius: 6px;
  margin-bottom: 2rem;
  padding: 1rem;
}
.timeline .timeline_dot_header>.MuiSvgIcon-root {
  font-size: 20px;
  color: darkslategray;
}
.timelineItem:last-child>div:first-child>span:last-child {
  width: 0px;
}