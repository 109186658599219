.ReactModal__Content {
    background-color: var(--main-color)
}

.modal-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.added-image {
    height: 360px;
}
.message{
    margin:1rem;
}
.take-home-button {
    border: 1px solid black;
    padding: 6px 16px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:1rem;
}

.take-home-button i {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;

}

@media only screen and (max-width: 1024px) {
    .added-image {
        height: 320px;
    }
}

@media only screen and (max-width: 1024px) {
    .added-image {
        height: 240px;
    }
}
.close-button{
    align-self: flex-end;
}