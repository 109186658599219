/* Contact.css */
.focus-styles {
  margin-bottom: 4rem;

  & input:focus + label {
    color: var(--main-color); /* Use MUI theme variable */
  }

  & input:focus {
    border-color: var(--main-color); /* Use MUI theme variable */
  }
}
