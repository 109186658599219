.profile {
	background: #fff;
	border-radius: 6px;
	width: 100%;
	display: inline-block;
	box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.07);
	-webkit-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.07);
}

.profile_name {
	line-height: 18px;
	padding: 20px;
}

.profile_name .name {
	text-transform: uppercase;
	font-size: 17px;
	font-weight: bold;
}

.profile_name .title {
	font-size: 13px;
	color: #333;
}

.profile_image {
	/* clip-path:  circle(20% at 23rem 35%);*/
	margin-top: -10px;
	-webkit-clip-path: polygon(0 9%, 100% 0, 100% 94%, 0% 100%);
	clip-path: polygon(0 9%, 100% 0, 100% 94%, 0% 100%);
}

.profile_image img {
	width: 100%;
}

.profile_information {
	top: 0;
	left: 0;
	margin: -44px 10px 30px;
}

.btn-container {
	display: flex;
	justify-content: center;
	width: "100%";
}

.timelineItem_text {
	font-size: 13.5px;

	line-height: 12px;
}

.timelineItem_value {
	color: #333;
	padding-left: 5px;
}
.timelineItem_text a {  
	text-decoration: none;
	padding-left: 5px;
}
.btn-container button:hover {
	background: #000;
	color: var(--main-color);
	border: 1px solid;
	font-weight: bold;
}
